/**
 * @param {Date} input
 */
 function connectionDuration(input) {
    if (!(input instanceof Date)) {
        return '';
    }

    let seconds = Math.floor((new Date() - input) / 1000);
    if (seconds < 0) {
        seconds *= -1;
    }
    const years = Math.floor(seconds / 31536000);
    seconds = seconds - (years * 31536000);

    const months = Math.floor(seconds / 2592000);
    seconds = seconds - (months * 2592000);

    const days = Math.floor(seconds / 86400);
    seconds = seconds - (days * 86400);

    const hours = Math.floor(seconds / 3600);
    seconds = seconds - (hours * 3600);

    const minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);

    let duration = `${strZero(hours, 2)}:${strZero(minutes, 2)}:${strZero(seconds, 2)}`;

    if (days >= 1) {
        return `${days}d ${duration}`;
    }
    
    return duration;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default connectionDuration