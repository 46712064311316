/**
 * @param {Date} input
 */
 function since(input) {
    if (!(input instanceof Date)) {
        return '';
    }

    let seconds = Math.floor((new Date() - input) / 1000);
    if (seconds < 0) {
        seconds *= -1;
    }
    const years = seconds / 31536000;
    const months = seconds / 2592000;
    const days = seconds / 86400;
    const hours = seconds / 3600;
    const minutes = seconds / 60;

    if (years >= 1) {
        return Math.floor(years) + "a";
    }

    if (months >= 1) {
        return Math.floor(months) + "M";
    }

    if (days >= 1) {
        return Math.floor(days) + "d";
    }

    if (hours >= 1) {
        return Math.floor(hours) + "h";
    }

    if (minutes >= 1) {
        return Math.floor(minutes) + "m";
    }
    
    return Math.floor(seconds) + "s";
}

export default since